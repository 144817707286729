import { useContext } from 'react';
import { ModeContext } from '../App.js';
import callIcon from './images/callIcon.png';
import emailIcon from './images/emailIcon.png';
import './Contact.css';

export default function Contact() {
    const { mode } = useContext(ModeContext);
    const [toggleMode] = mode;

    return(
        <div id='contactSec' style={{background: toggleMode ? 'linear-gradient(to bottom, rgb(34, 43, 69), rgb(25, 32, 56)) no-repeat' : 'linear-gradient(to bottom, rgb(255, 255, 255), rgb(247, 250, 255)) no-repeat'}}>
            <div className='hdrCont'>
                <h1 className='hdr' style={{color: toggleMode ? 'whitesmoke' : 'rgb(30, 32, 34)'}}>Let us be your solution.</h1>
                <h2 className='subtxt' style={{color: toggleMode ? 'rgb(174, 176, 180)' : 'rgb(103, 119, 136)'}}>CodePalma is waiting to turn your ideas into reality. We look forward to hearing from you!</h2>
            </div>
            <div className='openIconCont'>
                <div className='openIcon'>
                    <div className='iconBorder' style={{backgroundColor: toggleMode ? 'rgb(33, 51, 83)' : 'rgb(235, 242, 255)'}}>
                        <img className='icon' src={callIcon} alt='callIcon'></img>
                    </div>
                    <h2 className='servHdr' style={{color: toggleMode ? 'whitesmoke' : 'rgb(30, 32, 34)'}}>Call Us</h2>
                    <h3 className='subtxt' style={{color: toggleMode ? 'rgb(174, 176, 180)' : 'rgb(103, 119, 136)'}}>+1 (501) 289-8274</h3>
                </div>
                <div className='openIcon'>
                    <div className='iconBorder' style={{backgroundColor: toggleMode ? 'rgb(33, 51, 83)' : 'rgb(235, 242, 255)'}}>
                        <img className='icon' src={emailIcon} alt='emailIcon'></img>
                    </div>
                    <h2 className='servHdr' style={{color: toggleMode ? 'whitesmoke' : 'rgb(30, 32, 34)'}}>Email Us</h2>
                    <h3 className='subtxt' style={{color: toggleMode ? 'rgb(174, 176, 180)' : 'rgb(103, 119, 136)'}}>codepalmawebdev@gmail.com</h3>
                </div>
            </div>
        </div>
    );
}