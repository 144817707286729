import { useEffect, useContext } from 'react';
import { ModeContext } from '../App.js';
import './MobileApps.css';

export default function MobileApps() {
    const { mode, resetScroll } = useContext(ModeContext);
    const [toggleMode] = mode;

    useEffect(() => {
        resetScroll();
    }, [window.location.pathname])

    return(
        <div id='mobAppsCont'>
            <div style={{background: toggleMode ? 'linear-gradient(to bottom, rgb(34, 43, 69), rgb(25, 32, 56)) no-repeat' : 'linear-gradient(to bottom, rgb(255, 255, 255), rgb(247, 250, 255)) no-repeat'}}>
                <div className='hdrCont' style={{padding: '24vh 0px'}}>
                    <h1 className='hdr' style={{color: toggleMode ? 'whitesmoke' : 'rgb(30, 32, 34)'}}>Examples of our work are coming soon!</h1>
                    <h2 className='subtxt' style={{color: toggleMode ? 'rgb(174, 176, 180)' : 'rgb(103, 119, 136)'}}>Until then please have a look at the features and style of our business website.</h2>
                </div>
            </div>
            {/* <div id='lower' style={{background: toggleMode ? 'linear-gradient(to bottom, rgb(34, 43, 69), rgb(25, 32, 56)) no-repeat' : 'linear-gradient(to bottom, rgb(255, 255, 255), rgb(247, 250, 255)) no-repeat'}}>
                <svg viewBox='0 0 1440 50'><path fill={toggleMode ? 'rgb(25, 32, 56)' : 'rgb(247, 250, 255)'} fillOpacity='1' d='M 0 0 Q 750 50 1450 0 Z'></path></svg>
                    
                <svg viewBox='0 0 1440 50'><path fill={toggleMode ? 'rgb(25, 32, 56)' : 'rgb(247, 250, 255)'} fillOpacity='1' d='M 0 50 Q 750 0 1450 50 Z'></path></svg>
            </div> */}
        </div>
    );
}