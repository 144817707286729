import { useContext } from 'react';
import { ModeContext } from '../App.js';
import reactIconDark from './images/reactIcon-dark.png';
import reactIconLight from './images/reactIcon-light.png';
import electronIconDark from './images/electronIcon-dark.png';
import electronIconLight from './images/electronIcon-light.png';
import capacitorIconDark from './images/capacitorIcon-dark.png';
import capacitorIconLight from './images/capacitorIcon-light.png';
import nodeIconDark from './images/nodeIcon-dark.png';
import nodeIconLight from './images/nodeIcon-light.png';
import expressIconDark from './images/expressIcon-dark.png';
import expressIconLight from './images/expressIcon-light.png';
import mongoIconDark from './images/mongoIcon-dark.png';
import mongoIconLight from './images/mongoIcon-light.png';
import './Tools.css';

export default function Tools() {
    const { mode } = useContext(ModeContext);
    const [toggleMode] = mode;

    return(
        <div>
            <div className='hdrCont'>
                <h1 className='hdr' style={{color: toggleMode ? 'whitesmoke' : 'rgb(30, 32, 34)'}}>The CodePalma developer toolbox.</h1>
                <h2 className='subtxt' style={{color: toggleMode ? 'rgb(174, 176, 180)' : 'rgb(103, 119, 136)'}}>Our developers are experienced in all of the following technologies and more.</h2>
            </div>
            <div id='toolSecCont'>
                <div>
                    <div className='boxBorder' style={{backgroundColor: toggleMode ? 'rgb(45, 54, 78)' : 'rgb(255, 255, 255)'}}>
                        <div className='altIconBorder'>
                            <img className='icon' src={toggleMode ? reactIconDark : reactIconLight} alt='reactIcon'></img>
                        </div>
                        <h2 className='servHdr' style={{color: toggleMode ? 'whitesmoke' : 'rgb(30, 32, 34)'}}>React</h2>
                        <h3 className='toolSubtxt' style={{color: toggleMode ? 'rgb(174, 176, 180)' : 'rgb(103, 119, 136)'}}>React is an industry standard library for building front-end user interfaces and is part of the MERN stack.</h3>
                    </div>
                </div>
                <div>
                    <div className='boxBorder' style={{backgroundColor: toggleMode ? 'rgb(45, 54, 78)' : 'rgb(255, 255, 255)'}}>
                        <div className='altIconBorder'>
                            <img className='icon' src={toggleMode ? electronIconDark : electronIconLight} alt='electronIcon'></img>
                        </div>
                        <h2 className='servHdr' style={{color: toggleMode ? 'whitesmoke' : 'rgb(30, 32, 34)'}}>Electron</h2>
                        <h3 className='toolSubtxt' style={{color: toggleMode ? 'rgb(174, 176, 180)' : 'rgb(103, 119, 136)'}}>Electron is a framework for developing desktop user interfaces using web development technologies.</h3>
                    </div>
                </div>
                <div>
                    <div className='boxBorder' style={{backgroundColor: toggleMode ? 'rgb(45, 54, 78)' : 'rgb(255, 255, 255)'}}>
                        <div className='altIconBorder'>
                            <img className='icon' src={toggleMode ? capacitorIconDark : capacitorIconLight} alt='capacitorIcon'></img>
                        </div>
                        <h2 className='servHdr' style={{color: toggleMode ? 'whitesmoke' : 'rgb(30, 32, 34)'}}>Capacitor</h2>
                        <h3 className='toolSubtxt' style={{color: toggleMode ? 'rgb(174, 176, 180)' : 'rgb(103, 119, 136)'}}>Capacitor is a cross-platform runtime for building mobile applications using web development technologies.</h3>
                    </div>
                </div>
                <div>
                    <div className='boxBorder' style={{backgroundColor: toggleMode ? 'rgb(45, 54, 78)' : 'rgb(255, 255, 255)'}}>
                        <div className='altIconBorder'>
                            <img className='icon' src={toggleMode ? nodeIconDark : nodeIconLight} alt='nodeIcon'></img>
                        </div>
                        <h2 className='servHdr' style={{color: toggleMode ? 'whitesmoke' : 'rgb(30, 32, 34)'}}>Node</h2>
                        <h3 className='toolSubtxt' style={{color: toggleMode ? 'rgb(174, 176, 180)' : 'rgb(103, 119, 136)'}}>Node is a cross-platform JavaScript runtime for back-end functionality in web applications and is part of the MERN stack.</h3>
                    </div>
                </div>
                <div>
                    <div className='boxBorder' style={{backgroundColor: toggleMode ? 'rgb(45, 54, 78)' : 'rgb(255, 255, 255)'}}>
                        <div className='altIconBorder'>
                            <img className='icon' src={toggleMode ? expressIconDark : expressIconLight} alt='expressIcon'></img>
                        </div>
                        <h2 className='servHdr' style={{color: toggleMode ? 'whitesmoke' : 'rgb(30, 32, 34)'}}>Express</h2>
                        <h3 className='toolSubtxt' style={{color: toggleMode ? 'rgb(174, 176, 180)' : 'rgb(103, 119, 136)'}}>Express is a back-end framework for building APIs in a Node runtime environment and is part of the MERN stack.</h3>
                    </div>
                </div>
                <div>
                    <div className='boxBorder' style={{backgroundColor: toggleMode ? 'rgb(45, 54, 78)' : 'rgb(255, 255, 255)'}}>
                        <div className='altIconBorder'>
                            <img className='icon' src={toggleMode ? mongoIconDark : mongoIconLight} alt='mongoIcon'></img>
                        </div>
                        <h2 className='servHdr' style={{color: toggleMode ? 'whitesmoke' : 'rgb(30, 32, 34)'}}>MongoDB</h2>
                        <h3 className='toolSubtxt' style={{color: toggleMode ? 'rgb(174, 176, 180)' : 'rgb(103, 119, 136)'}}>MongoDB is a NoSQL cross-platform database program and is part of the MERN stack.</h3>
                    </div>
                </div>
            </div>
        </div>
    );
}