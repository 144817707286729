import { useContext } from 'react';
import { ModeContext } from '../App.js';
import mobileIcon from './images/mobileIcon.png';
import desktopIcon from './images/desktopIcon.png';
import websiteIcon from './images/websiteIcon.png';
import './Services.css';

export default function Services() {
    const { mode } = useContext(ModeContext);
    const [toggleMode] = mode;

    return(
        <div id='aboutSec'>
            <div className='hdrCont'>
                <h1 className='hdr' style={{color: toggleMode ? 'whitesmoke' : 'rgb(30, 32, 34)'}}>CodePalma utilizes modern technologies to bring your ideas to life.</h1>
                <h2 className='subtxt' style={{color: toggleMode ? 'rgb(174, 176, 180)' : 'rgb(103, 119, 136)'}}>From commercial projects to personal purposes, we can help you solve it.</h2>
            </div>
            <div className='openIconCont'>
                <div className='openIcon'>
                    <div className='iconBorder' style={{backgroundColor: toggleMode ? 'rgb(33, 51, 83)' : 'rgb(235, 242, 255)'}}>
                        <img className='icon' src={mobileIcon} alt='mobileIcon'></img>
                    </div>
                    <h2 className='servHdr' style={{color: toggleMode ? 'whitesmoke' : 'rgb(30, 32, 34)'}}>Mobile Applications</h2>
                    <h3 className='subtxt' style={{color: toggleMode ? 'rgb(174, 176, 180)' : 'rgb(103, 119, 136)'}}>We develop high-end mobile applications using the Capacitor runtime.</h3>
                </div>
                <div className='openIcon'>
                    <div className='iconBorder' style={{backgroundColor: toggleMode ? 'rgb(33, 51, 83)' : 'rgb(235, 242, 255)'}}>
                        <img className='icon' src={desktopIcon} alt='desktopIcon'></img>
                    </div>
                    <h2 className='servHdr' style={{color: toggleMode ? 'whitesmoke' : 'rgb(30, 32, 34)'}}>Desktop Applications</h2>
                    <h3 className='subtxt' style={{color: toggleMode ? 'rgb(174, 176, 180)' : 'rgb(103, 119, 136)'}}>We develop native desktop applications using the Electron framework.</h3>
                </div>
                <div className='openIcon'>
                    <div className='iconBorder' style={{backgroundColor: toggleMode ? 'rgb(33, 51, 83)' : 'rgb(235, 242, 255)'}}>
                        <img className='icon' src={websiteIcon} alt='websiteIcon'></img>
                    </div>
                    <h2 className='servHdr' style={{color: toggleMode ? 'whitesmoke' : 'rgb(30, 32, 34)'}}>Websites</h2>
                    <h3 className='subtxt' style={{color: toggleMode ? 'rgb(174, 176, 180)' : 'rgb(103, 119, 136)'}}>We develop responsive websites utilizing a variety of web development technologies.</h3>
                </div>
            </div>
        </div>
    );
}