import { useState, createContext } from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import Home from './home/Home.js';
import ToggleSwitch from './toggleSwitch/ToggleSwitch.js';
import MobileApps from './mobileApps/MobileApps.js';
import Websites from './websites/Websites.js';
import PrivacyPolicy from './policies/PrivacyPolicy.js';
import navLogoDark from './app_img/navLogoDark.png';
import navLogoLight from './app_img/navLogoLight.png';
import hmIcon from './app_img/hmIcon.png';
import './App.css';

export const ModeContext = createContext();

function App() {
  const initialMode = JSON.parse(localStorage.getItem('mode')) || false;

  const [toggleHm, setToggleHm] = useState(false);
  const [toggleMode, setToggleMode] = useState(initialMode);
  
  const hmHandler = () => {
    if (!toggleHm) {
      document.querySelector('#hmLinkCont').style = 'display: block;';
    }
    else if (toggleHm) {
      document.querySelector('#hmLinkCont').style = 'display: none;';
    }
    setToggleHm((toggle) => !toggle)
  }

  window.addEventListener('resize', () => {
    if (window.innerWidth >= 768) {
      document.querySelector('#hmLinkCont').style = 'display: none;';
      if (toggleHm) {
        setToggleHm(false);
      }
    }
  });

  const callToAction = (target) => {
    const info = document.querySelector(`${target}`)
    info.scrollIntoView({ behavior: 'smooth' });
  }

  const resetScroll = () => {
    setTimeout(() => window.scrollTo(0, 0), 10);
  }

  return (
    <BrowserRouter>
      <ModeContext.Provider value={{mode: [toggleMode, setToggleMode], hm: [toggleHm, setToggleHm], resetScroll}}>
        <div id='hmLinkCont' onClick={() => hmHandler()}>
          <div id='hmLinks' style={{backgroundColor: toggleMode ? 'rgb(67, 75, 97)' : 'rgb(255, 255, 255)'}}>
            <div id='hmLogo'>
              <Link to='/'>
                <img src={toggleMode ? navLogoDark : navLogoLight} alt='Home'></img>
              </Link>
            </div>
            <div id='hmRoutes'>
              <Link to='/apps' className='hmLink' style={{color: toggleMode ? 'whitesmoke' : 'rgb(30, 32, 34)'}}>Apps</Link>
              <Link to='/websites' className='hmLink' style={{color: toggleMode ? 'whitesmoke' : 'rgb(30, 32, 34)'}}>Websites</Link>
              <Link to='/' className='hmLink' style={{color: toggleMode ? 'whitesmoke' : 'rgb(30, 32, 34)'}} onClick={() => setTimeout(() => callToAction('#aboutSec'), 200)}>About</Link>
              <Link to='/'>
                <div id='hmContactBtn' onClick={() => setTimeout(() => callToAction('#contactSec'), 200)}>
                  Contact Us
                </div>
              </Link>
            </div>
          </div>
        </div>
        <nav style={{backgroundColor: toggleMode ? 'rgb(34, 43, 69)' : 'rgb(255, 255, 255)'}}>
          <div id='navLogo'>
            <Link to='/'>
              <img src={toggleMode ? navLogoDark : navLogoLight} alt='Home'></img>
            </Link>
          </div>
          <div id='navLinks'>
            <Link to='/apps' className='navLink' style={{color: toggleMode ? 'whitesmoke' : 'rgb(30, 32, 34)'}}>Apps</Link>
            <Link to='/websites' className='navLink' style={{color: toggleMode ? 'whitesmoke' : 'rgb(30, 32, 34)'}}>Websites</Link>
            <Link to='/' className='navLink' style={{color: toggleMode ? 'whitesmoke' : 'rgb(30, 32, 34)'}} onClick={() => setTimeout(() => callToAction('#aboutSec'), 200)}>About</Link>
            <Link to='/'>
              <div id='contactBtn' onClick={() => setTimeout(() => callToAction('#contactSec'), 200)}>
                Contact Us
              </div>
            </Link>
            <ToggleSwitch/>
          </div>
          <div id='mobileOnly'>
            <ToggleSwitch/>
            <div id={toggleMode ? 'hmDark' : 'hmLight'} onClick={() => hmHandler()} style={{border: toggleMode ? '1px solid rgb(74, 77, 87)' : '1px solid rgb(204, 204, 204)'}}>
              <div id='hmCont'>
                <img src={hmIcon} alt='hmIcon'></img>
              </div>
            </div>
          </div>
        </nav>
        <main>
          <Routes>
            <Route exact path='/' element={<Home/>}></Route>
            <Route exact path='/apps' element={<MobileApps/>}></Route>
            <Route exact path='/websites' element={<Websites/>}></Route>
            <Route exact path='/privacy_policy' element={<PrivacyPolicy/>}></Route>
            <Route path='*' element={<Home/>}></Route>
          </Routes>
        </main>
        <footer style={{backgroundColor: toggleMode ? 'rgb(25, 32, 56)' : 'rgb(247, 250, 255)'}}>
          <div id='ftrLogo'>
            <Link to='/'>
              <img src={toggleMode ? navLogoDark : navLogoLight} alt='CodePalma' onClick={() => resetScroll()}></img>
            </Link>
          </div>
          <div id='coprCont'>
            <p id='copr' style={{color: toggleMode ? 'rgb(174, 176, 180)' : 'rgb(103, 119, 136)'}}>© 2021, CodePalma LLC. All rights reserved.</p>
          </div>
          <div id='privPolicyCont'>
            <p id='privPolicyDesc' style={{color: toggleMode ? 'rgb(174, 176, 180)' : 'rgb(103, 119, 136)'}}>By visiting our site or using our services, you agree to the collection and use of information in relation with our privacy <span><Link to='/privacy_policy' style={{color: toggleMode ? 'rgb(174, 176, 180)' : 'rgb(103, 119, 136)'}}>policy</Link></span>. The information that we collect is used for providing and improving our services. We will not use or share your information with anyone except as described in this <span><Link to='/privacy_policy' style={{color: toggleMode ? 'rgb(174, 176, 180)' : 'rgb(103, 119, 136)'}}>policy</Link></span>.</p>
          </div>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', color: toggleMode ? 'rgb(174, 176, 180)' : 'rgb(103, 119, 136)', fontFamily: 'Roboto, sans-serif', fontSize: '12px', paddingBottom: '10px'}}>
            <p>Website by Easton Yarbrough</p>
          </div>
        </footer>
      </ModeContext.Provider>
    </BrowserRouter>
  );
}

export default App;