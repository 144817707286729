import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModeContext } from '../App.js';
import './SloganWidget.css';

export default function SloganWidget() {
    const navigate = useNavigate();

    const { mode } = useContext(ModeContext);
    const [toggleMode] = mode;

    useEffect(() => {
        let tempTxt = '';
        let wordCount = 0;
        let letCount = 0;
        
        const addLet = (string, letter) => {
            tempTxt = `${string}${letter}`;
            document.querySelector('#sloganAnim').textContent = tempTxt;
        }

        const sloganAnim = () => {
            const wordArr = ['application.', 'website.', 'solution.'];

            if (!wordArr[wordCount]) {
                setTimeout(() => wordCount = 0, 800);
            }
            else if (wordArr[wordCount]) {
                if (!wordArr[wordCount].charAt(letCount)) {
                    letCount = 0;
                    wordCount++;
                    tempTxt = '';
                }
                else if (wordArr[wordCount].charAt(letCount)) {
                    addLet(tempTxt, wordArr[wordCount].charAt(letCount));
                    letCount++;
                }
            }
        }

        const runAnim = setInterval(sloganAnim, 200)

        return () => {
            clearInterval(runAnim)
        }
    }, []);
    
    return(
        <div>
            <div>
                <h1 className='sloganTxt' style={{color: toggleMode ? 'whitesmoke' : 'rgb(30, 32, 34)'}}>
                    Your online space,
                </h1>
                <h1 className='sloganTxt' style={{color: toggleMode ? 'whitesmoke' : 'rgb(30, 32, 34)'}}>
                    your&nbsp;
                    <span id='sloganAnim'></span>
                    <span id='animIP'>|</span>
                </h1>
            </div>
            <div id='sloganSubtxt'>
                <h2 className='altSubtxt' style={{color: toggleMode ? 'rgb(174, 176, 180)' : 'rgb(103, 119, 136)'}}>Check out some of our work using the links below!</h2>
                <h2 className='altSubtxt' style={{color: toggleMode ? 'rgb(174, 176, 180)' : 'rgb(103, 119, 136)'}}>Consider us for your next website, application or tech solution.</h2>
            </div>
            <div>
                <div className='viewBtn' id='viewAppsBtn' onClick={() => navigate('/apps')}>
                    View our apps
                </div>
                <div className='viewBtn' id={toggleMode ? 'viewSitesBtnDark' : 'viewSitesBtnLight'}  onClick={() => navigate('/websites')} style={{border: toggleMode ? '1px solid rgb(74, 77, 87)' : '1px solid rgb(204, 204, 204)'}}>
                    View our websites
                </div>
            </div>
        </div>
    );
}