import { useEffect, useContext } from 'react';
import { ModeContext } from '../App.js';
import switchBkgDark from './images/switchBkgDark.png';
import switchBkgLight from './images/switchBkgLight.png';
import './ToggleSwitch.css';

export default function ToggleSwitch() {
  const { mode } = useContext(ModeContext);
  const [toggleMode, setToggleMode] = mode;

  useEffect(() => {
    const inputList = [...document.querySelectorAll('.switchInput')];

    inputList.forEach(e => {
      if (toggleMode === true) {
        e.checked = true;
      }
      else if (toggleMode === false) {
        e.checked = false;
      }
    })
    
    localStorage.setItem('mode', JSON.stringify(toggleMode));
  }, [toggleMode])

  return(
    <label className='switch'>
        <input type='checkbox' className='switchInput' onClick={() => setToggleMode((toggle) => !toggle)}></input>
        <span className='slider' style={{background: toggleMode ? `url(${switchBkgDark}) no-repeat` : `url(${switchBkgLight}) no-repeat`}}></span>
    </label>
  );
}