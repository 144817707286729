import { useEffect, useContext } from 'react';
import { ModeContext } from '../App.js';
import SloganWidget from '../sloganWidget/SloganWidget.js';
import SlideWidget from '../slideWidget/SlideWidget.js';
import Services from '../services/Services.js';
import Tools from '../tools/Tools.js';
import Contact from '../contact/Contact.js'
import './Home.css';

export default function Home() {
    const { mode, resetScroll } = useContext(ModeContext);
    const [toggleMode] = mode;

    useEffect(() => {
        resetScroll();
    }, [window.location.pathname])

    return(
        <div>
            <div id='flexWidgCont' style={{background: toggleMode ? 'linear-gradient(to bottom, rgb(34, 43, 69), rgb(25, 32, 56)) no-repeat' : 'linear-gradient(to bottom, rgb(255, 255, 255), rgb(247, 250, 255)) no-repeat'}}>
                <div id='sloganWidgCont'>
                    <SloganWidget/>
                </div>
                <div id='slideWidgCont'>
                    <SlideWidget/>
                </div>
            </div>
            <div id='lower' style={{background: toggleMode ? 'linear-gradient(to bottom, rgb(34, 43, 69), rgb(25, 32, 56)) no-repeat' : 'linear-gradient(to bottom, rgb(255, 255, 255), rgb(247, 250, 255)) no-repeat'}}>
                <svg viewBox='0 0 1440 50'><path fill={toggleMode ? 'rgb(25, 32, 56)' : 'rgb(247, 250, 255)'} fillOpacity='1' d='M 0 0 Q 750 50 1450 0 Z'></path></svg>
                <Services/>
                <Tools/>
                <svg viewBox='0 0 1440 50'><path fill={toggleMode ? 'rgb(34, 43, 69)' : 'rgb(255, 255, 255)'} fillOpacity='1' d='M 0 50 Q 750 0 1450 50 Z'></path></svg>
                <Contact/>
            </div>
        </div>
    );
}