import { useContext } from 'react';
import { ModeContext } from '../App.js';
import slidePlaceholderDark from './images/slidePlaceholderDark.png';
import slidePlaceholderLight from './images/slidePlaceholderLight.png';
import './SlideWidget.css';

export default function SlideWidget() {
    const { mode } = useContext(ModeContext);
    const [toggleMode] = mode;

    return(
        <div>
            <img className='icon' src={toggleMode ? slidePlaceholderDark : slidePlaceholderLight} alt='placeholder' id='slide' style={{border: toggleMode ? '6px solid rgb(34, 43, 69)' : '6px solid rgb(255, 255, 255)'}}></img>
        </div>
    );
}